import { Typography } from 'antd';
import React from 'react';
import { EXPLORER_ITEM_TYPE, EXPLORER_VIEW_TYPE } from '../../constants/ProjectExplorer';

const ExplorerItem = ({ onlyExplorer, viewType, isSelected, explorerItem, onClick, onDblClick }) => {
    const handleDblClickItem = () => {
        if (onDblClick && explorerItem.type === EXPLORER_ITEM_TYPE.FOLDER) {
            onDblClick(explorerItem);
        }
    };
    const handleClickItem = () => {
        if (onlyExplorer && explorerItem.disabled) {
            return;
        }
        if (onClick) {
            onClick(explorerItem);
        }
    };

    return (
        <div
            title={explorerItem.title}
            onClick={handleClickItem}
            onDoubleClick={handleDblClickItem}
            className={`flex w-full ${viewType === 'grid' ? 'justify-center flex-col' : ''} ${
                onlyExplorer && explorerItem.disabled ? 'cursor-not-allowed text-gray-300' : 'cursor-pointer'
            } items-center hover:bg-primary ${isSelected ? 'bg-primary' : ''}`}
        >
            <span className={`${viewType === EXPLORER_VIEW_TYPE.LIST ? 'text-xl p-1' : 'text-3xl'}`}>
                {explorerItem.icon}
            </span>
            <Typography.Text disabled={onlyExplorer && explorerItem.disabled} className={`${viewType === EXPLORER_VIEW_TYPE.LIST ? 'ml-1' : ''}`} ellipsis>
                {explorerItem.title}
            </Typography.Text>
        </div>
    );
};

export default ExplorerItem;
