import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Form, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import FolderService from '../../../services/folder.service';
import { notificationError, requestError } from '../../../helpers/notification';
import { generateString } from '../../../helpers/string-helper';
import { USER_ACCESS } from '../../../helpers/planning';
import activityService from '../../../services/activity.service';
import { ALL_JOBS } from '../../../constants/Jobs';
import { selectTeamList } from '../../../redux/slices/app/user.slice';

const AddProjectPermissionModal = forwardRef(({ selected, permissions, onPermissionAdded, planningJobs }, ref) => {
    const { t } = useTranslation('translation', { keyPrefix: 'projects' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const [isVisible, setVisible] = useState(false);
    const [multipleTeams, setMultipleTeams] = useState([]);
    const [singleTeams, setSingleTeams] = useState([]);
    const [permission, setPermission] = useState({
        teamId: null,
        access: USER_ACCESS.NONE,
        jobNames: [],
    });
    const [isPropagate, setPropagate] = useState(false);
    const [form] = Form.useForm();
    const teamList = useSelector(selectTeamList)

    useImperativeHandle(ref, () => ({
        openModal() {
            setVisible(true);
        },
    }));

    useEffect(() => {
        setMultipleTeams(teamList.filter((team) => !team.single));
        setSingleTeams(teamList.filter((team) => team.single));
    }, []);

    useEffect(() => {
        setMultipleTeams(teamList.filter((team) => !team.single));
        setSingleTeams(teamList.filter((team) => team.single));
    }, [teamList]);

    const permissionExists = (teamId, access) =>
        permissions.filter((permissionObj) => permissionObj.team.id === teamId && permissionObj.access === access)
            .length > 0;

    const onCancel = () => {
        setVisible(false);
        form.resetFields();
    };

    const createFolderPermission = (folderId, teamId, access) => {
        FolderService.createFolderPermission(folderId, teamId, access, isPropagate)
            .then((data) => {
                onPermissionAdded(data);
                setVisible(false);
                form.resetFields();
            })
            .catch((error) => {
                requestError(error, t('permission_create_error'));
            });
    };

    const createPlanningPermission = (planningId, teamId, access, jobNames) => {
        FolderService.createPlanningPermission(planningId, teamId, access, isPropagate)
            .then((data) => {
                if (access === USER_ACCESS.READWRITE && jobNames.length > 0) {
                    activityService.updateJob(selected.rootActivityId, teamId, jobNames).then(() => onPermissionAdded(data));
                } else {
                    onPermissionAdded(data);
                }
                setVisible(false);
                form.resetFields();
            })
            .catch((error) => {
                requestError(error, t('permission_create_error'));
            });
    };

    const isFolder = () => selected?.key?.split('-')[0] === 'folder';
    const isPlanning = () => !isFolder();

    const onFinish = ({ teamId, access }) => {
        const [type, id] = selected.key.split('-');
        console.log(type, isPropagate);
        if (permissionExists(teamId, access)) {
            notificationError(t('permission_create_error'), t('permission_already_exist'));
            return;
        }
        if (isFolder()) createFolderPermission(id, teamId, access, isPropagate);
        if (isPlanning()) createPlanningPermission(id, teamId, access, permission.jobNames);
    };

    const handleJobsChange = (value, oldPermission) => {
        // if (value.length === 0) {
        //     return;
        // }
        let jobsToBeSelected = [];

        if (oldPermission.jobNames.indexOf(ALL_JOBS) === -1 && value.indexOf(ALL_JOBS) > -1) {
            jobsToBeSelected = [ALL_JOBS];
        } else {
            jobsToBeSelected = [...value];
        }
        if (oldPermission.jobNames.indexOf(ALL_JOBS) > -1) {
            jobsToBeSelected = value.filter((i) => i !== ALL_JOBS);
        }
        setPermission((old) => ({ ...old, jobNames: jobsToBeSelected }));
    };
    return (
        <Modal
            title={t('add_team')}
            className="add-project-permission-modal"
            centered
            open={isVisible}
            onCancel={onCancel}
            footer={null}
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    name="teamId"
                    label={generalTranslation('team')}
                    rules={[{ required: true, message: t('team_required') }]}
                >
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(val) => setPermission((old) => ({ ...old, teamId: val }))}
                    >
                        <Select.OptGroup label={generalTranslation('teams_plural')}>
                            {multipleTeams.map((team) => (
                                <Select.Option key={generateString(6)} value={team.id}>{team.name}</Select.Option>
                            ))}
                        </Select.OptGroup>
                        <Select.OptGroup label={generalTranslation('users_plural')}>
                            {singleTeams.map((team) => (
                                <Select.Option key={generateString(6)} value={team.id}>{team.name}</Select.Option>
                            ))}
                        </Select.OptGroup>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="access"
                    label={generalTranslation('access')}
                    rules={[{ required: true, message: t('access_required') }]}
                >
                    <Select onChange={(val) => setPermission((old) => ({ ...old, access: val }))}>
                        <Select.Option value="NONE">{generalTranslation('access_none')}</Select.Option>
                        <Select.Option value="READ">{generalTranslation('access_read')}</Select.Option>
                        <Select.Option value="WRITE">{generalTranslation('access_write')}</Select.Option>
                    </Select>
                </Form.Item>
                {isPlanning() && (
                    <Form.Item label={generalTranslation('jobs')} required validateStatus={permission.jobNames.length === 0 &&  permission.access === USER_ACCESS.READWRITE ? 'error' : ''}>
                        <Select
                            disabled={permission.access !== USER_ACCESS.READWRITE}
                            mode="multiple"
                            value={permission.jobNames}
                            onChange={(val) => handleJobsChange(val, permission)}
                        >
                            {planningJobs.map((choice) => (
                                <Select.Option key={generateString(6)} value={choice}>
                                    {choice}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
                <div className="mb-5">
                    <Checkbox checked={isPropagate} onChange={() => setPropagate(!isPropagate)} />
                    <span style={{ marginLeft: '10px' }}>
                        {isFolder() ? t('propagate_folder') : t('propagate_archive')}
                    </span>
                </div>
                <Form.Item className="mb-0">
                    <Button type="primary" htmlType="submit">
                        {generalTranslation('add')}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
});

AddProjectPermissionModal.propTypes = {
    selected: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired,
    onPermissionAdded: PropTypes.func.isRequired,
};

export default AddProjectPermissionModal;