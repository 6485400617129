import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { Button, Col, Form, Input, Row } from 'antd';
import styled from 'styled-components';
import AuthService from '../../services/auth.service';
import { requestError } from '../../helpers/notification';
import walletService from '../../services/wallet.service';

const ActionsRow = styled.div`
    text-align: right;
    margin-bottom: 20px;
`;

const PasswordModification = ({ onCancel, showProfileDetailsForm }) => {
    const currentUser = AuthService.getCurrentUser();
    const [loading, setLoading] = useState(false);
    const service = walletService;
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const updatePassword = async (values) => {
        try {
            await service.updatePassword(values);
            setLoading(false);
            showProfileDetailsForm();
        } catch (error) {
            setLoading(false);
            requestError(error, t('error_message.update_password_error'));
        }
    };

    const submitForm = (values) => {
        if (loading) {
            return;
        }

        setLoading(true);

        updatePassword(values);
    };

    return (
        <>
            <ActionsRow>
                <Button type="primary" htmlType="button" onClick={showProfileDetailsForm}>
                    {t('update_password_modal.update_profile')}
                </Button>
            </ActionsRow>
            <Form form={form} layout="vertical" name="profile_form" initialValues={currentUser} onFinish={submitForm}>
                <Form.Item
                    label={t('update_password_modal.current_password')}
                    name="current_password"
                    rules={[{ required: true, message: t('general.required_field') }]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label={t('update_password_modal.new_password')}
                    name="new_password"
                    rules={[
                        { required: true, message: t('general.required_field') },
                        {
                            pattern: new RegExp(
                                /^\S*(?=\S{6,})(?=\S*[a-z]+)(?=\S*[A-Z]+)(?=\S*[0-9]+)(?=\S*[?!#%$]+)\S*$/
                            ),
                            message: t('general.invalid_format'),
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label={t('update_password_modal.confirm_new_password')}
                    name="new_password_confirmation"
                    rules={[
                        {
                            required: true,
                            message: t('general.required_field'),
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('new_password') === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(
                                    new Error(
                                        t('error_message.password_confirmation_error')
                                    )
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Row>
                    <Col span={24} className="text-right">
                        <Button htmlType="button" className="mr-2" onClick={onCancel}>
                            {t('general.cancel')}
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {t('general.save')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

PasswordModification.propTypes = {
    onCancel: PropTypes.func.isRequired,
    showProfileDetailsForm: PropTypes.func.isRequired,
};

export default PasswordModification;
